import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Share } from '@material-ui/icons';
import CustomButton from './CustomButton';

const useStyles = makeStyles((theme) => createStyles({
  shareLink: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    fontFamily: '"Roboto"',
    minWidth: '200px',
    outline: 'none',
  },
  popover: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: '10px',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
}));

const SharePopover = (props) => {
  const { text, createLink, disabled } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    createLink();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  const copyToClipboard = () => {
    const copyText = document.getElementById('shareLink');

    copyText.select();
    copyText.setSelectionRange(0, copyText.value.length);

    document.execCommand('copy');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <CustomButton name="Share" onClick={handleClick} icon={<Share />} disabled={disabled} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: classes.popover }}
      >
        <input
          readOnly
          className={classes.shareLink}
          type="text"
          value={text}
          id="shareLink"
          onFocus={handleFocus}
          data-testid="share-link"
        />
        <IconButton aria-label="copy-link" onClick={copyToClipboard}>
          <FileCopyIcon classes={{ root: classes.icon }} />
        </IconButton>
      </Popover>
    </div>
  );
};

SharePopover.propTypes = {
  text: PropTypes.string.isRequired,
  createLink: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SharePopover.defaultProps = {
  disabled: false,
};

export default SharePopover;
