/* eslint-disable no-console */
if (process.env.NODE_ENV === 'production') {
  const noop = () => {};
  module.exports = {
    log: noop,
    warn: noop,
    error: noop,
  };
} else {
  module.exports = {
    log: console.log,
    warn: console.warn,
    error: console.error,
  };
}
