import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import style from './TextField.module.css';

const CHARACTER_REMAINING_WARN_THRESHOLD = 100;

const useStyles = makeStyles((theme) => createStyles({
  textArea: {
    height: '100%',
    fontFamily: '"Roboto"',
    '&::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '15px',
      left: '10px',
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0.5)',
      borderRadius: '25px',
      cursor: 'pointer',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'rgba(0,0,0,0.7)',
    },
  },
  textParent: {
    width: '100%',
    height: '100%',
    padding: '20px',

  },
}));

const CustomTextArea = (props) => {
  const {
    currentText, setText, placeholder, maxCharacters,
  } = props;

  const characterCount = [...currentText].length;
  const charactersLeft = maxCharacters - characterCount;
  let limitMessage = '';
  if (charactersLeft < CHARACTER_REMAINING_WARN_THRESHOLD) {
    limitMessage = `${charactersLeft} characters remaining`;
  }

  const classes = useStyles();
  return (
    <div className={style.textArea}>
      <InputBase
        classes={{
          root: classes.textParent,
        }}
        multiline
        rows={5}
        onChange={(e) => setText(e.target.value)}
        value={currentText}
        placeholder={placeholder}
        inputProps={{
          className: classes.textArea,
          'aria-label': 'naked',
          maxLength: maxCharacters,
        }}
      />
      <span className={style.limitMessage}>{limitMessage}</span>
    </div>
  );
};

CustomTextArea.propTypes = {
  currentText: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  maxCharacters: PropTypes.number,
};

CustomTextArea.defaultProps = {
  placeholder: undefined,
  maxCharacters: undefined,
};

export default CustomTextArea;
