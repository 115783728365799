/**
 * Waits at least the given delay until resolving the given promise.
 */
export default async function throttle(promise, delayMs) {
  const results = await Promise.all([
    new Promise((resolve) => {
      setTimeout(resolve, delayMs);
    }),
  ].concat(promise));
  results.shift();
  if (results.length === 1) {
    return results[0];
  }
  return results;
}
