import React, { useState } from 'react';
import FeedbackIcon from '@material-ui/icons/Feedback';
import style from './RightDisplay.module.css';
import CustomButton from './CustomButton';
import FeedbackBox from './FeedbackBox';

const RightDisplay = () => {
  const [feedbackVisible, setFeedbackVisibile] = useState(false);
  const [currentText, setText] = useState('');
  const onFeedbackClick = () => {
    setFeedbackVisibile(!feedbackVisible);
  };
  return (
    <div className={style.right}>
      <div className={style.bottomRight}>
        <CustomButton name="Feedback" onClick={() => onFeedbackClick()} icon={<FeedbackIcon />} />
        {feedbackVisible
          ? <FeedbackBox currentText={currentText} setText={setText} close={onFeedbackClick} />
          : null}
      </div>
    </div>
  );
};

export default RightDisplay;
