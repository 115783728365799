import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createMuiTheme, StylesProvider } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core';
import MainDisplay from './components/MainDisplay';
import RightDisplay from './components/RightDisplay';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ededf4',
    },
    secondary: {
      main: '#246c46',
    },
    buttonText: {
      text: '#F9F9F9',
    },
    hover: {
      primary: '#d2d2d8',
      secondary: '#1c5436',
    },
    action: {
      disabled: '#a6a6a6',
      disabledBackground: '#e0e0e0',
    },
  },
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <div className="App">
          <Router>
            <Routes>
              <Route path="/share/:text/:language/:engine/:voice" element={<MainDisplay />} />
              <Route path="*" element={<MainDisplay />} />
            </Routes>
          </Router>
          <RightDisplay />
        </div>
      </StylesProvider>
    </MuiThemeProvider>
  );
}

export default App;
