import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import FileSaver from 'file-saver';
import { useParams } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import style from './MainDisplay.module.css';
import CustomDropdown from './CustomDropdown';
import CustomTextArea from './CustomTextArea';
import CustomButton from './CustomButton';
import CustomMenuButton from './CustomMenuButton';
import SharePopover from './SharePopover';
import throttle from '../utils/throttle';
import logger from '../utils/logger';

const MIN_LOADING_ANIMATION_MS = 300;

const MainDisplay = () => {
  const {
    text: paramText,
    language: paramLanguage,
    engine: paramEngine,
    voice: paramVoice,
  } = useParams();
  const engineData = ['MaryTTS', 'Festival'];
  const languagesData = ['English', 'Maori'];
  const initialLanguage = languagesData.includes(paramLanguage) ? paramLanguage : languagesData[0];
  const initialEngine = engineData.includes(paramEngine) ? paramEngine : engineData[0];

  // Initialise state using params if they are available.
  const [currentText, setText] = useState(paramText || '');
  const [allVoicesForEngine, setAllVoicesForEngine] = useState([]);
  const [desiredVoice, setDesiredVoice] = useState(paramVoice);
  const [currentLanguage, setLanguage] = useState(initialLanguage);
  const [currentEngine, setEngine] = useState(initialEngine);
  const [shareLink, setShareLink] = useState('');
  const [fileTypes, setFileTypes] = useState([]);
  const [message, setMessage] = useState('');
  const [playState, setPlayState] = useState([]);
  const [currentAudio, setCurrentAudio] = useState();
  const [isLoading, setLoading] = useState();

  // Voices available for the current language.
  const chosenLocale = currentLanguage === 'English' ? 'en_NZ' : 'mi';
  const voicesAvailable = allVoicesForEngine
    .filter((voice) => voice[1] === chosenLocale)
    .map((voice) => voice[0]);
  const chosenVoice = voicesAvailable.includes(desiredVoice)
    ? desiredVoice
    : voicesAvailable[0];

  // Disable states.
  const isLoadingVoices = allVoicesForEngine.length === 0;
  const noVoices = voicesAvailable.length === 0;
  const isTextEmpty = currentText.length === 0;
  const isDisabled = noVoices || isTextEmpty || isLoading;
  let playButtonMessage = 'Play';
  if (isTextEmpty) playButtonMessage = 'Waiting for text...';
  if (noVoices) playButtonMessage = 'No voices';
  if (isLoadingVoices) playButtonMessage = 'Loading voices...';

  const stateCompare = { currentText, desiredVoice, currentEngine };
  const isPlaying = stateCompare.currentText === playState.currentText
                    && stateCompare.desiredVoice === playState.desiredVoice
                    && stateCompare.currentEngine === playState.currentEngine;

  // Update list of voices whenever the engine changes.
  // Also loads the list of voices the first time.
  // Tries to keep the existing voice setting, but updates it
  // to the first voice in the list if the old voice is not available.
  useEffect(() => {
    let isMounted = true;

    async function getEngineInfo() {
      // Let user know we are currently loading the voices list.
      setAllVoicesForEngine([]);

      // Load the voices list, but don't load it too fast that it confuses the user.
      const params = new URLSearchParams();
      params.append('engine', currentEngine);
      try {
        const [
          responseVoices,
          responseFileTypes,
        ] = await throttle([
          axios.get(`/api/getVoices?${params}`),
          axios.get(`/api/getFileTypes?${params}`),
        ], MIN_LOADING_ANIMATION_MS);

        if (isMounted) {
          if (responseVoices.status === 200 && responseFileTypes.status === 200) {
            const newVoices = responseVoices.data;
            const newFileTypes = responseFileTypes.data;

            setAllVoicesForEngine(newVoices);
            setFileTypes(newFileTypes);
            return;
          }
        }
      } catch (error) {
        logger.error(error);
      }

      if (isMounted) {
        setMessage('Sorry, but the voices could not be loaded');
      }
    }
    getEngineInfo();

    return () => {
      // Do not update state after component gets unmounted.
      isMounted = false;
    };
  }, [currentEngine]);

  // Update desired voice when it is not available and an alternative was chosen.
  useEffect(() => {
    if (chosenVoice) {
      setDesiredVoice(chosenVoice);
    }
  }, [desiredVoice, chosenVoice]);

  const getAudioFile = async (fileType) => {
    setLoading(true);
    const params = new URLSearchParams();
    params.append('text', currentText);
    params.append('engine', currentEngine);
    params.append('voice', chosenVoice);
    params.append('fileType', fileType);
    try {
      const response = await axios.get(`/api/requestAudio?${params}`, {
        responseType: 'arraybuffer',
      });
      logger.log(response);

      if (response) {
        return new Blob([response.data], { type: 'audio/wav' });
      }
    } catch (error) {
      logger.log(error);
    }
    setMessage('Sorry, but the audio could not be loaded.');
    return null;
  };

  const audioEl = useRef();

  const onPlayClick = async () => {
    const audioFile = await getAudioFile('.wav');
    if (audioFile) {
      const url = window.URL.createObjectURL(audioFile);
      logger.log(
        `Playing: ${chosenVoice} with ${currentEngine}: ${currentText}`,
      );
      setPlayState(stateCompare);
      setCurrentAudio(url);
      setTimeout(() => {
        if (audioEl.current) {
          audioEl.current.play();
        }
      }, 300);
    }
    setLoading(false);
  };

  const onDownload = async (fileType) => {
    const audioFile = await getAudioFile(fileType);
    if (audioFile) {
      const filename = `voice${fileType}`;
      logger.log(`Download: ${fileType}`);
      FileSaver.saveAs(audioFile, filename);
    }
    setLoading(false);
  };

  const onShare = () => {
    const encodedText = encodeURIComponent(currentText);
    const encodedLanguage = encodeURIComponent(currentLanguage);
    const encodedEngine = encodeURIComponent(currentEngine);
    const encodedVoice = encodeURIComponent(chosenVoice);
    setShareLink(`${window.location.origin}/share/`
      + `${encodedText}/${encodedLanguage}/${encodedEngine}/${encodedVoice}`);
  };

  const clearMessage = () => {
    setMessage('');
  };

  return (
    <div className={style.main}>
      <p className={style.title}>Aotearoa Voices</p>
      <CustomTextArea
        currentText={currentText}
        setText={setText}
        placeholder="Enter some text"
        maxCharacters={1000}
      />
      <div className={style.dropdown}>
        <CustomDropdown
          data={engineData}
          title="Engine"
          current={currentEngine}
          onChange={setEngine}
        />
        <CustomDropdown
          data={languagesData}
          title="Language"
          current={currentLanguage}
          onChange={setLanguage}
        />
        <CustomDropdown
          isLoading={isLoadingVoices}
          data={voicesAvailable}
          title="Voice"
          current={chosenVoice}
          onChange={setDesiredVoice}
        />
      </div>
      <div>
        {isPlaying
          ? (
            <audio controls ref={audioEl} className={style.audio}>
              <track kind="captions" />
              <source src={currentAudio} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          )
          : (
            <CustomButton
              name={playButtonMessage}
              disabled={isDisabled}
              onClick={() => onPlayClick()}
            />
          )}
      </div>
      <div className={style.doubleButton}>
        <CustomMenuButton
          disabled={isDisabled}
          data={fileTypes}
          name="Download"
          onDownload={onDownload}
        />
        <SharePopover text={shareLink} createLink={onShare} disabled={isDisabled} />
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={message.length > 0}
        onExited={clearMessage}
        message={message}
        action={(
          <IconButton onClick={clearMessage} color="inherit">
            <CloseIcon />
          </IconButton>
        )}
      />
    </div>
  );
};

export default MainDisplay;
