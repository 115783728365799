import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReCAPTCHA from 'react-google-recaptcha';
import style from './FeedbackBox.module.css';
import CustomTextArea from './CustomTextArea';
import CustomLightButton from './CustomLightButton';
import logger from '../utils/logger';

const clientKey = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY;

function onChange(value) {
  logger.log('Captcha value:', value);
}

const FeedbackBox = (props) => {
  const { currentText, setText, close } = props;
  const recaptchaRef = React.createRef();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState('');
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [formCompleted, setFormCompleted] = React.useState(true);

  const dialogTitle = 'FEEDBACK SUBMISSION';

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    if (formCompleted) {
      setDialogOpen(false);
      close();
    } else {
      setFormCompleted(true);
      setDialogOpen(false);
    }
  };

  const handleBackdropOpen = () => {
    setBackdropOpen(true);
  };

  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  const onSubmit = async () => {
    if (currentText) {
      handleBackdropOpen();
      const recaptchaValue = recaptchaRef.current.getValue();
      const apiURL = '/api/feedback';
      const params = {
        feedback: currentText,
        captchaValue: recaptchaValue,
      };

      try {
        const response = await axios.post(apiURL, params);

        if (response.status === 201) {
          handleBackdropClose();
          setDialogContent('Feedback successfully submitted');
          setText('');
          handleDialogOpen();
        }
      } catch (error) {
        if (error.response.status === 500) {
          handleBackdropClose();
          setDialogContent('Server error. Please try again later');
          setText('');
          handleDialogOpen();
        } else if (error.response.status === 401) {
          handleBackdropClose();
          setDialogContent(
            'Please complete the captcha before clicking submit',
          );
          setFormCompleted(false);
          handleDialogOpen();
        }
      }
    } else {
      setDialogContent(
        "The feedback textbox is empty. Please enter some feedback before clicking on 'Submit'",
      );
      setFormCompleted(false);
      handleDialogOpen();
    }
  };

  return (
    <div className={style.feedbackBoxOpen}>
      <div className={style.container}>
        <div className={style.top}>
          <p className={style.heading}>Feedback</p>
          <IconButton className={style.button} aria-label="close" onClick={close}>
            <CloseIcon color="primary" />
          </IconButton>
        </div>
        <p className={style.subtitle}>How was the speech?</p>
        <CustomTextArea currentText={currentText} setText={setText} />
        <Backdrop
          className={style.backdrop}
          aria-label="backdrop"
          open={backdropOpen}
        >
          <CircularProgress color="primary" />
        </Backdrop>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogContent}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} variant="contained" color="black">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <div className={style.captcha}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={clientKey}
            onChange={onChange}
          />
        </div>
        <CustomLightButton name="Submit" onClick={() => onSubmit()} />
      </div>
    </div>
  );
};

FeedbackBox.propTypes = {
  currentText: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default FeedbackBox;
