import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  button: {
    height: '100%',
    width: '100%',
    borderRadius: '4px',
    color: theme.palette.hover.primary,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '5px 5px #888888',
    '&:hover': {
      backgroundColor: theme.palette.hover.secondary,
    },
    transition: theme.transitions.create(['background-color', 'box-shadow', 'color'], {
      duration: theme.transitions.duration.short,
    }),
  },
  buttonText: {
    color: theme.palette.buttonText.text,
    fontFamily: '"Roboto"',
  },
  disabled: {
    color: theme.palette.action.disabled,
    backgroundColor: theme.palette.action.disabledBackground,
    boxShadow: 'none',
  },
}));

const CustomButton = (props) => {
  const {
    name,
    onClick,
    className,
    icon,
    disabled,
    classes: overrideClasses,
  } = props;
  const classes = useStyles();
  return (
    <Button
      onClick={onClick}
      className={className == null ? classes.button : className}
      classes={({
        root: classes.buttonText,
        disabled: classes.disabled,
        ...overrideClasses,
      })}
      startIcon={icon}
      disabled={disabled}
    >
      {name}
    </Button>
  );
};

CustomButton.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.any),
};

CustomButton.defaultProps = {
  className: null,
  icon: null,
  disabled: false,
  classes: null,
};

export default CustomButton;
