import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  button: {
    height: '100%',
    width: '100%',
    borderRadius: '4px',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    boxShadow: '5px 5px #888888',
    '&:hover': {
      backgroundColor: theme.palette.hover.primary,
    },
  },
  buttonText: {
    color: theme.palette.hover.main,
    fontFamily: '"Roboto"',
  },
}));

const CustomLightButton = (props) => {
  const {
    name, onClick, className, icon,
  } = props;
  const classes = useStyles();
  return (
    <Button
      onClick={onClick}
      className={className == null ? classes.button : className}
      classes={{ root: classes.buttonText }}
      startIcon={icon}
    >
      {name}
    </Button>
  );
};

CustomLightButton.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  icon: PropTypes.element,
};

CustomLightButton.defaultProps = {
  className: null,
  icon: null,
};

export default CustomLightButton;
