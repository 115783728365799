import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { ArrowDropDown } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import GetAppIcon from '@material-ui/icons/GetApp';
import style from './CustomMenuButton.module.css';
import CustomButton from './CustomButton';

const useStyles = makeStyles((theme) => createStyles({
  button: {
    height: '100%',
    width: '100%',
    color: theme.palette.buttonText.text,
    borderRadius: '4px 0 0 4px',
    backgroundColor: theme.palette.secondary.main,
    paddingLeft: '50px',
    boxShadow: '5px 5px #888888',
    '&:hover': {
      backgroundColor: theme.palette.hover.secondary,
    },
    transition: theme.transitions.create(['background-color', 'box-shadow', 'color'], {
      duration: theme.transitions.duration.short,
    }),
  },
  iconButton: {
    height: '100%',
    width: '100%',
    color: theme.palette.buttonText.text,
    borderRadius: '0 4px 4px 0',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '5px 5px #888888',
    '&:hover': {
      backgroundColor: theme.palette.hover.secondary,
    },
    transition: theme.transitions.create(['background-color', 'box-shadow', 'color'], {
      duration: theme.transitions.duration.short,
    }),
  },
  dropdown: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    '& ul': {
      padding: 0,
    },
    '& ul > li': {
      borderBottom: '0.5px solid rgba(0,0,0,0.5)',
      fontFamily: '"Roboto"',
    },
    '& ul > li:last-child': {
      borderBottom: 'none',
    },
  },
  disabled: {
    color: theme.palette.action.disabled,
    backgroundColor: `${theme.palette.action.disabledBackground} !important`,
    boxShadow: 'none',
  },
}));
const CustomMenuButton = (props) => {
  const {
    name, onDownload, data, disabled,
  } = props;
  const classes = useStyles();

  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuClick = (value) => {
    onDownload(value);
    handleClose();
  };

  const prevOpen = useRef(isOpen);
  useEffect(() => {
    if (prevOpen.current === true && isOpen === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = isOpen;
  }, [isOpen]);

  // For popper TransitionProps spread:
  /* eslint-disable react/jsx-props-no-spreading */

  return (
    <div
      className={style.menuButton}
      ref={anchorRef}
    >
      <CustomButton
        name={name}
        className={classes.button}
        classes={{
          disabled: classes.disabled,
        }}
        onClick={() => onDownload('.wav')}
        icon={<GetAppIcon />}
        disabled={disabled}
      />
      <div>
        <IconButton
          className={classes.iconButton}
          classes={{
            disabled: classes.disabled,
          }}
          onClick={handleToggle}
          disabled={disabled}
          data-testid={`${name}-dropdown-btn`}
        >
          <ArrowDropDown />
        </IconButton>
        <Popper
          open={isOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ width: `${anchorRef.current != null ? anchorRef.current.clientWidth : 0}px` }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper classes={{ root: classes.dropdown }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={isOpen}>
                    { data.map((item) => (
                      <MenuItem
                        className={classes.dropdownItem}
                        value={item}
                        key={`Download_item_${item}`}
                        onClick={() => handleMenuClick(item)}
                      >
                        Download
                        {' '}
                        {item}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}

        </Popper>
      </div>
    </div>
  );
};

CustomMenuButton.propTypes = {
  name: PropTypes.string.isRequired,
  onDownload: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
};

CustomMenuButton.defaultProps = {
  disabled: false,
};

export default CustomMenuButton;
