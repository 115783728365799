import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import style from './CustomDropdown.module.css';

const useStyles = makeStyles((theme) => createStyles({
  selectMenu: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    border: '2px solid #7d7b7a',
    paddingLeft: '4px',
    '&:hover': {
      backgroundColor: theme.palette.hover.primary,
      border: '2px solid #3b3a3a',
    },
    fontFamily: '"Roboto"',
  },
  title: {
    color: `${theme.palette.secondary.main} !important`,
    fontFamily: '"Roboto"',
  },
  menuPaper: {
    maxHeight: 100,
    padding: '0 10 0 10px',
    overflowY: 'auto',
    backgroundColor: theme.palette.primary.main,
    '&::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '15px',
      left: '10px',
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0.5)',
      borderRadius: '25px',
      cursor: 'pointer',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'rgba(0,0,0,0.7)',
    },
  },
  selectedItem: {
    backgroundColor: `${theme.palette.hover.primary} !important`,
    color: theme.palette.hover.secondary,
    fontFamily: '"Roboto"',
  },
}));

const CustomDropdown = (props) => {
  const {
    data, title, onChange, current, isLoading,
  } = props;
  const classes = useStyles();

  const selectId = `select-${title}`;

  const noData = data.length === 0;
  const unavailable = noData || isLoading;

  return (
    <FormControl className={style.parent} fullWidth>
      <InputLabel htmlFor={selectId} className={classes.title} shrink>{title}</InputLabel>
      <Select
        disableUnderline
        classes={{
          selectMenu: classes.selectMenu,
          icon: classes.title,
        }}
        inputProps={{
          id: selectId,
        }}
        MenuProps={{
          classes: {
            paper: classes.menuPaper,
          },
        }}
        displayEmpty
        disabled={unavailable}
        className={classes.select}
        value={unavailable ? '' : current}
        onChange={(e) => onChange(e.target.value)}
      >
        { unavailable && (
          <MenuItem
            className={style.item}
            classes={{
              selected: classes.selectedItem,
              root: classes.select,
            }}
            value=""
            key={`${title}_unavailable`}
            disabled
          >
            {isLoading ? 'Loading...' : 'None available'}
          </MenuItem>
        )}
        { data.map((item) => (
          <MenuItem
            className={style.item}
            classes={{
              selected: classes.selectedItem,
              root: classes.select,
            }}
            value={item}
            key={`${title}_item_${item}`}
          >
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CustomDropdown.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  current: PropTypes.string,
  isLoading: PropTypes.bool,
};

CustomDropdown.defaultProps = {
  current: null,
  isLoading: false,
};

export default CustomDropdown;
